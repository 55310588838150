import React from "react"
import {
    Layout, MainContainer,    
} from '../app/components'

export default (props) => {    
   
    return(       
        <Layout location={props.location}>  
            <MainContainer>
                Calendario
            </MainContainer> 
        </Layout>                                      
    )
}
